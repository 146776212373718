import React from "react";
import {Link} from "react-router-dom";

export default function NotFoundPage() {
    return (
        <div>
            <h2>Página não encontrada</h2>
            <p>Desculpe, a página que você está procurando não existe.</p>
            <Link to="/">Voltar para a página inicial</Link>
        </div>
    );
}
