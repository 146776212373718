import { Contact, ContactTyte, PersonCnpj, PersonCpf, User } from "../types/user";

export interface UserResponse {
    success: boolean;
    code: number;
    data: {
        user: {
            username: string;
            person: {
                id: number;
                person_cpf?: {
                    name: string;
                    gender: string;
                    cpf: string;
                    rg?: string;
                    birth_date: string;
                };
                person_cnpj?: {
                    fantasy_name: string;
                    cnpj: string;
                    state_registration_number?: string;
                    foundation_date?: string;
                    website?: string;
                };
                contacts: [{
                    id: number;
                    data: string;
                    created_at: string;
                    updated_at: string;
                    type: {
                        id: number;
                        name: string;
                        created_at: string;
                        updated_at: string;
                    };
                }];
            };
        };
        token?: string;
        created_at?: string;
    };
}

export const createUserModel = (response: UserResponse): User => {
    const user: User = {
        id: response.data.user.person.id,
        username: response.data.user.username,
    };

    if (response.data.user.person.person_cpf) {
        user.personCpf = {
            name: response.data.user.person.person_cpf.name,
            gender: response.data.user.person.person_cpf.gender,
            cpf: response.data.user.person.person_cpf.cpf,
            rg: response.data.user.person.person_cpf.rg,
            birthDate: new Date(response.data.user.person.person_cpf.birth_date),
        } as PersonCpf;
    }

    if (response.data.user.person.person_cnpj) {
        user.personCnpj = {
            fantasyName: response.data.user.person.person_cnpj.fantasy_name,
            cnpj: response.data.user.person.person_cnpj.cnpj,
            stateRegistrationNumber: response.data.user.person.person_cnpj.state_registration_number,
            foundationDate: response.data.user.person.person_cnpj.foundation_date,
            website: response.data.user.person.person_cnpj.website,
        } as PersonCnpj;
    }

    if (response.data.user.person.contacts) {
        user.contacts = response.data.user.person.contacts.map((el) => {
            return {
                id: el.id,
                data: el.data,
                createdAt: new Date(`${el.created_at}`),
                updatedAt: new Date(`${el.updated_at}`),
                type: {
                    id: el.type.id,
                    name: el.type.name,
                    createdAt: new Date(`${el.type.created_at}`),
                    updatedAt: new Date(`${el.type.updated_at}`),
                } as ContactTyte
            } as Contact
        });
    }

    return user;
}