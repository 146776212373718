import React, { useRef, useState, ChangeEvent, useEffect } from "react";
import { Alert, Button, Col, Container, FormFeedback, FormGroup, Input, InputGroup, Label, Row, Spinner } from "reactstrap";

import { ProcessImportService } from "../../services/ProcessImportService";
import { WrapSpinnerProcessImport } from "./ProcessImportStyles";

import DefaultHeader from "../../components/Hearders/DefaultHeader";

interface KeywordInputProps {
    keywords: string[];
    setKeywords: React.Dispatch<React.SetStateAction<string[]>>;
}

const ProcessImportPage: React.FC = () => {
    useEffect(() => setIsLoading(false), []);

    const [keywords, setKeywords] = useState<string[]>([]);
    const [typeUseWords, setTypeUseWords] = useState<string>('separates');
    const [errors, setErrors] = useState<string[]>([]);

    const [messages, setMessages] = useState<string[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const addKeyword = () => {
        const existsEmptyElements = keywords.filter(i => !i);
        if (!existsEmptyElements.length) {
            setKeywords([...keywords, ""]);
        }
    };

    const handleTypeUseWords = (event: ChangeEvent<HTMLInputElement>) => setTypeUseWords(event.target.value);

    const handleSend = async () => {
        const newErrors: string[] = [];
        if (!keywords.length) {
            newErrors.push('Add at least one keyword.');
        }

        if (!selectedFile) {
            newErrors.push('Select a file.');
        }

        setIsLoading(true);
        setErrors(newErrors);

        if (newErrors.length === 0) {
            const { success, data } = await ProcessImportService.processImportServiceImport({
                keywords,
                file: selectedFile,
                typeUseWords: typeUseWords,
                typeProcessImportId: 1
            });

            if (success) {
                setKeywords([]);
                setErrors([]);
                setSelectedFile(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
                setMessages(['IMPORT SUCCESSFUL']);
                setTimeout(() => setMessages([]), 3000);
            } else {
                console.log('data:', data);
                setErrors([data]);
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            <DefaultHeader />
            <Container>
                <Row>
                    <Col>
                        <h1 className="mt-3 text-uppercase">Search By Keywords</h1>
                        <FormGroup className="mt-5">
                            <div className="d-flex justify-content-between mb-1">
                                <Label htmlFor="inputfile" className="m-0">
                                    Select the spreadsheet with the legal cases.
                                </Label>
                                <a href="/examples.xlsx" target="_blank">Download Example</a>
                            </div>
                            <Input
                                id="inputfile"
                                name="inputfile"
                                type="file"
                                multiple={false}
                                innerRef={fileInputRef}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (event.currentTarget.files) {
                                        setSelectedFile(event.currentTarget.files[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <div className="d-flex justify-content-between align-items-center">
                            <Label onClick={addKeyword} className="m-0">Phrases to be searched:</Label>
                            <div className="d-flex align-items-center">
                                <FormGroup check>
                                    <Input 
                                        type="radio" 
                                        name="radioTypeUseWords" 
                                        id="typeUseWordSeparated" 
                                        value={'separates'} 
                                        defaultChecked
                                        onChange={handleTypeUseWords}
                                    />
                                    <Label check htmlFor="typeUseWordSeparated">
                                        Separated
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="mx-3">
                                    <Input 
                                        type="radio" 
                                        name="radioTypeUseWords" 
                                        id="typeUseWordAll" 
                                        value={'all'} 
                                        onChange={handleTypeUseWords}
                                    />
                                    <Label check htmlFor="typeUseWordAll">
                                        All
                                    </Label>
                                </FormGroup>
                                <Button color="primary" onClick={addKeyword}>
                                    To Add
                                </Button>
                            </div>
                        </div>
                        <hr className="mt-1" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <KeywordInput keywords={keywords} setKeywords={setKeywords} />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Button
                            color="primary"
                            className={`w-100 mt-3 position-relative${isLoading ? ` disabled` : ''}`}
                            disabled={isLoading}
                            style={{ maxWidth: 300 }}
                            type="button" 
                            onClick={handleSend}
                        >
                            Send
                            {
                                isLoading
                                    ? <WrapSpinnerProcessImport><Spinner color='light' size='sm' /></WrapSpinnerProcessImport>
                                    : null
                            }
                        </Button>
                    </Col>
                </Row>
                <Message messages={errors} type="danger" />
                <Message messages={messages} type="success" />
            </Container>
        </>
    );
}

const Message: React.FC<{ messages: string[], type: string }> = ({ messages, type }) => {
    return messages.length > 0 ? (
        <Row className="mt-5">
            <Col>
                {messages.map((msg, index) => <Alert key={index} color={type}>{msg}</Alert>)}
            </Col>
        </Row>
    ) : null;
}

const KeywordInput: React.FC<KeywordInputProps> = ({ keywords, setKeywords }) => {
    const [invalidIndices, setInvalidIndices] = useState<number[]>([]);

    const handleKeywordChange = (index: number, value: string) => {
        const newKeywords = [...keywords];
        newKeywords[index] = value;
        setKeywords(newKeywords);
        validateKeywords(newKeywords);
    };

    const removeKeyword = (index: number) => {
        const newKeywords = keywords.filter((_, i) => i !== index);
        setKeywords(newKeywords);
        validateKeywords(newKeywords);
    };

    const validateKeywords = (newKeywords: string[]) => {
        const keywordMap: { [key: string]: number[] } = {};
        const duplicates: number[] = [];

        newKeywords.forEach((keyword, index) => {
            if (keywordMap[keyword]) {
                keywordMap[keyword].push(index);
            } else {
                keywordMap[keyword] = [index];
            }
        });

        Object.values(keywordMap).forEach(indices => {
            if (indices.length > 1) {
                duplicates.push(...indices);
            }
        });

        setInvalidIndices(duplicates);
    };

    return (
        <>
            {keywords.map((keyword, index) => (
                <FormGroup key={index}>
                    <Label for={`keyword${index}`}>
                        Word {index + 1}
                    </Label>
                    <InputGroup>
                        <Input
                            id={`keyword${index}`}
                            value={keyword}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleKeywordChange(index, e.target.value)}
                            placeholder="Enter the keyword"
                            invalid={invalidIndices.includes(index)}
                        />
                        <Button
                            color="danger"
                            onClick={() => removeKeyword(index)}
                        >
                            Remove
                        </Button>
                        {
                            invalidIndices.includes(index) && <FormFeedback>Duplicate keyword</FormFeedback>
                        }
                    </InputGroup>
                </FormGroup>
            ))}
        </>
    );
}

export default ProcessImportPage;
