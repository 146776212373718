import { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu
} from 'reactstrap';
import { useAuth } from '../../providers/Auth/AuthProvider';

const DefaultHeader = (args: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const { user, logout } = useAuth();

    return (
        <Navbar {...args} expand={'md'} container={'sm'} className={'border-bottom shadow-sm'}>
            <NavbarBrand href="/">
                SYSVRP
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="me-auto" navbar>
                    <NavItem>
                        <NavLink href="/">Home</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            Robots
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem href='/search-config'>Search By Keywords</DropdownItem>
                            <DropdownItem disabled>Download Court Case</DropdownItem>
                            <DropdownItem href='/movements'>Movements</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            Robots Results
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem href='/batches-searches'>Search By Keywords</DropdownItem>
                            <DropdownItem disabled>Download Court Case</DropdownItem>
                            <DropdownItem href='/movements-download'>Download Court Case</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            Reports
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem href='/reports/bradesco'>Bradesco Bank</DropdownItem>
                            <DropdownItem disabled>Next Bank</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavLink className={'ms-2'} disabled={true}>
                        <small>Beta Version</small>
                    </NavLink>
                </Nav>
                <Nav className="ms-auto" navbar>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            {user?.alias ?? (user?.personCpf?.name ?? user?.personCnpj?.fantasyName)}
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem href='/users/profile'>
                                Profile
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={logout}>Logout</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Collapse>
        </Navbar>
    )
}

export default DefaultHeader;
