import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, FormGroup, Input, Label, Row, Spinner } from "reactstrap";

import { ProcessImportService } from "../../services/ProcessImportService";

import DefaultHeader from "../../components/Hearders/DefaultHeader";
import { WrapSpinnerMovement } from "./MovementStyles";

const MovementPage = () => {
    useEffect(() => setIsLoading(false), []);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [errors, setErrors] = useState<string[]>([]);
    const [messages, setMessages] = useState<string[]>([]);

    const handleSend = async () => {
        const newErrors: string[] = [];

        if (!selectedFile) {
            newErrors.push('Select a file.');
        }

        setIsLoading(true);
        setErrors(newErrors);

        if (newErrors.length === 0) {
            const { success, data } = await ProcessImportService.processImportServiceImport({
                file: selectedFile,
                typeProcessImportId: 2
            });

            if (success) {
                setErrors([]);
                setSelectedFile(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
                setMessages(['IMPORT SUCCESSFUL']);
                setTimeout(() => setMessages([]), 3000);
            } else {
                console.log('data:', data);
                setErrors([data]);
            }
        }

        setIsLoading(false);
    }

    return (
        <>
            <DefaultHeader />
            <Container>
                <Row>
                    <Col>
                        <h1 className="mt-3 text-uppercase">Movements</h1>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xl={10}>
                        <FormGroup>
                            <div className="d-flex justify-content-between mb-1">
                                <Label htmlFor="inputfile" className="m-0">
                                    Select the spreadsheet with the legal cases.
                                </Label>
                                <a href="/examplesmovement.xlsx" target="_blank">Download Example</a>
                            </div>
                            <Input
                                id="inputfile"
                                name="inputfile"
                                type="file"
                                multiple={false}
                                innerRef={fileInputRef}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (event.currentTarget.files) {
                                        setSelectedFile(event.currentTarget.files[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col xl={2} className="d-flex flex-column justify-content-end">
                        <Button 
                            color="primary" 
                            className={`mb-3 position-relative${isLoading ? ` disabled` : ''}`}
                            disabled={isLoading}
                            type="button"
                            onClick={handleSend}
                        >
                            Send
                            {
                                isLoading
                                    ? <WrapSpinnerMovement>
                                        <Spinner color='light' size='sm' />
                                        </WrapSpinnerMovement>
                                    : null
                            }
                        </Button>
                    </Col>
                </Row>
                <Message messages={errors} type="danger" />
                <Message messages={messages} type="success" />
            </Container>
        </>
    );
};

const Message: React.FC<{ messages: string[], type: string }> = ({ messages, type }) => {
    return messages.length > 0 ? (
        <Row className="mt-5">
            <Col>
                {messages.map((msg, index) => <Alert key={index} color={type}>{msg}</Alert>)}
            </Col>
        </Row>
    ) : null;
}

export default MovementPage;