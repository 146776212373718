import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "reactstrap";

import DefaultHeader from "../../../components/Hearders/DefaultHeader";
import AxiosSingleton from "../../../services/AxiosSingleton";

interface FileItems {
    id?: number;
    file: string;
    createdAt: Date;
}

interface RowTableProps {
    index: number,
    file: string,
    createdAt: Date,
    key?: number,
    id?:number
}

const BradescoReport = () => {
    const [items, setItems] = useState<FileItems[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const response = await AxiosSingleton.getInstance().get(`/api/report-monthly`);
                const { data } = response.data;

                setItems(data.map((item: any) => ({
                    id: item.id,
                    file: item.file,
                    createdAt: new Date(item.createdAt)
                })));
            } catch (error) {
                console.error("Erro ao buscar os relatórios:", error);
            }
        })()
    }, []);

    return (
        <>
            <DefaultHeader />
            <Container>
                <Row>
                    <Col>
                        <Table hover striped>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>File</th>
                                    <th className="text-end">Date</th>
                                    <th className="text-center">Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.length === 0 ? (
                                    <tr>
                                        <td className="text-center" colSpan={4}>
                                            No Elements
                                        </td>
                                    </tr>
                                ) : (
                                    items.map((item, index) => (
                                        <RowTable
                                            key={index}
                                            index={index + 1}
                                            file={item.file}
                                            createdAt={item.createdAt}
                                            id={item.id}
                                        />
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

const RowTable: React.FC<RowTableProps> = ({ index, file, createdAt, id }) => {
    const handleDownload = async () => {
        try {
            const response = await AxiosSingleton.getInstance().get(`/api/report-monthly/download/${id}`, {
                responseType: 'blob', // Define o tipo de resposta como blob para download de arquivos
            });

            // Criar uma URL para o blob e simular um clique para iniciar o download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file); // Nome do arquivo para download
            document.body.appendChild(link);
            link.click();
            link.remove(); // Remove o link do DOM após o clique
        } catch (error) {
            console.error("Erro ao fazer o download:", error);
        }
    };

    return (
        <tr>
            <th>{index}</th>
            <td>{file}</td>
            <td className="text-end">{createdAt.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</td>
            <td className="text-center">
                <Button color="primary" size="sm" onClick={handleDownload}>
                    Download
                </Button>
            </td>
        </tr>
    );
};


export default BradescoReport;
