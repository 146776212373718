import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, Link} from 'react-router-dom';

import {useAuth} from "../../providers/Auth/AuthProvider";

import { Spinner } from 'reactstrap';
import { ButtonTogglePassword, FormFloating, FormSignin, LogoImage, PasswordInput, UsernameInput, WrapSpinnerLogin } from './LoginPageStyles';

import logo from './../../assets/logopretopng.png';

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {login} = useAuth();

    useEffect(() => setIsLoading(false), []);

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const from = (location.state as { from: { pathname: string } })?.from?.pathname || "/";

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            await login(username, password);
            navigate(from, {replace: true});
        } catch (error) {
            console.error('Erro ao efetuar login:', error);
        }
    };

    return (
        <>
            <div className="d-flex justify-content-center align-items-center h-100">
                <FormSignin className='w-100'>
                    <form onSubmit={handleSubmit}>
                        <h1 className="h3 mb-3 fw-normal text-center">
                            <LogoImage src={logo} style={{maxWidth: 72}}/>
                        </h1>
                        <FormFloating>
                            <UsernameInput
                                type='text'
                                className="form-control"
                                id="username"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <label htmlFor="username">Username</label>
                        </FormFloating>
                        <FormFloating>
                            <PasswordInput
                                type={showPassword ? 'text' : 'password'}
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <ButtonTogglePassword type="button" onClick={toggleShowPassword}>
                                <small className='text-uppercase'>Show</small>
                            </ButtonTogglePassword>
                            <label htmlFor="password">Password</label>
                        </FormFloating>
                        <button 
                            className={`btn btn-primary w-100 py-2 position-relative${ isLoading ? ` disabled` : '' }`} 
                            disabled={isLoading}
                            type="submit"
                        >
                            Sign in
                            { 
                                isLoading 
                                    ? <WrapSpinnerLogin>
                                        <Spinner color='light' size='sm'/>
                                        </WrapSpinnerLogin>
                                    : null
                            }
                        </button>
                        <div className="w-100 text-end">
                            <Link to={'/forgot-password'}>
                                <small>Forgot your password?</small>
                            </Link>
                        </div>
                    </form>
                </FormSignin>
            </div>
        </>
    );
};

export default LoginPage;
