import React, { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {AuthProvider} from "./providers/Auth/AuthProvider";

import { routes } from './routes';

const router = createBrowserRouter(routes);

function App() {
    useEffect(() => {document.title = `${process.env.REACT_APP_NAME}`},[]);
    return (
        <AuthProvider>
            <AppContent />
        </AuthProvider>
    );
}

const AppContent = () => {
    useEffect(() => {document.title = `${process.env.REACT_APP_NAME}`}, []);

    return <RouterProvider router={router} />;
}

export default App;
