import styled from "styled-components";

export const FormSignin = styled.main`
    max-width: 330px;
    margin-top: -100px;
    padding: 1rem;
`;

export const FormFloating = styled.div.attrs(() => ({className: "form-floating"}))`
    &:focus-within {
        z-index: 2;
    }
`;

export const UsernameInput = styled.input`
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
`;

export const LogoImage = styled.img`
    max-width: 150px;
    width: 100%;
    height: auto;
`;

export const ButtonTogglePassword= styled.button`
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    border: none;
    border-bottom-right-radius: var(--bs-border-radius);
`;

export const PasswordInput = styled.input`
    margin-bottom: 10px;
    padding-right: 60px!important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:focus + ${ButtonTogglePassword} {
        border: 1px solid #86b7fe;
        border-left: none;
    }
`;

export const WrapSpinnerLogin = styled.div`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
`;