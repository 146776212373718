import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useAuth} from "../providers/Auth/AuthProvider";

interface PrivateRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<PrivateRouteProps> = ({children}) => {
    const {isAuthenticated} = useAuth();
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
